import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import config from './config';

firebase.initializeApp(config);

// Export firebase stuff.
export const { auth, firestore, storage, functions } = firebase;
export default firebase;
